.navbar-logo {
  height: 100%;
}

.navbar-custom {
  background-color: #f5998b;
}

/* Adjust navbar size */

@media (max-width: 1199px) {
  .navbar-brand {
    width: 60px;
    height: 60px;
    padding-top:5px;
    padding-bottom:5px;
  }
}

@media (min-width: 1200px) {
  .navbar-brand {
    height: 100px;
    position : absolute;
    top: 10px;
  }
}