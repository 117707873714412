.memberCard {
  margin-bottom: 20px;
  height: 97%;
  border-width: 4px;
}

.memberDescription {
  text-align: center;
}

.memberImage {
  max-height: 200px;
  border-radius: 10px;
}

.memberTitle {
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: center;
}