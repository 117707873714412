.sectionDivider {
  border-color: #f7998a;
}

.card {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.cardHeader {
  background-color: #fdddd7;
}