.sectionDivider {
  border-color: #fdddd7;
}

.card {
  width: 100%;
}

.cardHeader {
  background-color: #fdddd7;
}

.donateIconColumn {
  margin-left: 15px;
  margin-right: 15px;
}